header{
    background-color: #24292e;
}
.search{
    color: hsla(0,0%,100%,.7);
    background-color: #24292e;
}


.button{
    background-color: #eff3f6;
    background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
}
.col{
    color: #24292e
}

.lang{
    height: 10px;
    width: 10px;
    background-color:  #701516;
    border-radius: 50%;
    display: inline-block;
}

.search-email {
        border-top-right-radius: 0;
        min-width: 300px;
        border-bottom-right-radius: 0;
}